<template>
  <div>
     
        <div v-if="pgLoading" class="property-query-area clearfix webkit-animation webkit-100per-536"></div>
        <div v-if="!pgLoading" class="property-query-area clearfix">
          <div class="col-md-12">
            <h3 class="text-uppercase bottom20 top15"
                :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
            </h3>
          </div>
          <section id="contact-us">
            <div class="contact" 
                :style="'background-color:#eee;'">
                <div class="container">
                    <div class="row">

                        <div class="col-md-12 col-sm-12 col-xs-12 ">
                            <div class="agent-p-contact">
                                <div v-if="!pgLoading" class="agetn-contact-2 bottom30">
                                    <p 
                                       :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                       <strong>Thank You for your inquiry! <br><br> We have gladly received your inquiry and one of our experts will be contacting you within 24 hours!</strong>
                                        <br>
                                        <br>
                                        P.s If you still haven't been contacted within 24 hours kindly check your Spam folder for a message from US! 

                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
           

        </div>

  </div>
</template>

<script>
export default {
    name: 'ContactForm',
    props: ['pgLoading'],
    data(){
        return {
            btnLoading: false,
            options: {
                placeholder: (this.$i18n.locale == 'ar') ? 'رقم الهاتف' : 'Phone Number'
            }, 
            row: {
                name: '',
                email_address: '',
                phone_number: '',
                message: '',
            },
        }
    },
    mounted() {},
    created() {},
    methods: {

    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
